import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Map from "../components/map"

import { Col, Image } from "react-bootstrap"

import WhatsappImage from "../images/whatsapp.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Contacto" />
    <Col>
      <h1>Contacto</h1>
      Para consultas, cotizaciones y mayor información puede comunicarse con
      Reigas – Representaciones Reinoso:
      <ul>
         <li>
          Telf: <b>+593 07-4076831</b>
        </li>
        <li>
          Cel: <b>+593 099 537 1857</b>
        </li>
      </ul>
      <h3>Tambien puedes escribirnos al Whatsapp: +593 099 537 1857</h3>
      <a href="https://api.whatsapp.com/send?phone=593995371857&text=Hola, me gustaria hacer una cotización&source=&data=">
        <Image src={WhatsappImage} height="100px" />
      </a>
      <hr />
      <Map />
    </Col>
  </Layout>
)

export default IndexPage
